import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    fallbacklng: "de",
    resources: {
      pl: {
        translation: {
          contact: "Kontakt",
          pricelist: "Cennik",
          pricelist2: "Cennik",
          competences: "Kompetencje",
          competences2: "Kompetencje",
          language: "DE",
          infoname: "Tłumacz przysięgły<br/> języka niemieckiego",
          do1: "Wykonuję tłumaczenia tekstów z dziedziny motoryzacji, edukacji, prawa, handlu, gospodarki, ekonomii, księgowości, ubezpieczeń, medycyny.",
          do2: "Współpracuję z firmami polskimi i niemieckimi, urzędami administracji państwowej oraz klientami indywidualnymi.",
          do3: "Zapraszam do współpracy,",
          from: "od",
          pricelistinfo1:
            "Jedna strona tłumaczenia to 1125 znaków ze spacjami w języku docelowym.<br/>Ceny podane są za każdą rozpoczętą stronę tłumaczenia.<br/><br/>Tłumaczenie na język polski / niemiecki:",
          pricelistinfo2:
            "Podane ceny są cenami umownymi i w przypadku większych zleceń lub nawiązania stałej współpracy podlegają negocjacji.",
          pricelistitem1: "1 strona tłumaczenia (100%)",
          pricelistitem2:
            "1 strona tłumaczenia sporządzona w dniu zlecenia (200%)",
          pricelistitem3: "Kopia (20%)",
          pricelistitem4: "Zaświadczenie odpisu pisma<br/>w języku obcym (30%)",
          pricelistitem5:
            "Sprawdzenie i zaświadczenie dostarczonego tłumaczenia (50%)",
          pricelistitem6: "Tłumaczenie ustne<br/>(za każdą rozpoczętą godzinę)",
          pricelistitem7: "Dokumenty samochodowe",
          pricelistitem8:
            "Tłumaczenie tekstu sporządzonego pismem odręcznym lub z trudnej do odczytania kopii lub tekstu fachowego (150%)",
          contactinfoname: "Tłumacz przysięgły języka niemieckiego",
          street: "44-200 Rybnik<br/>ul. Borki 64F",
          phonenumber: "Tel. komórkowy +48 603 58 60 70",
          place: "NIP 642-267-22-97<br/>Regon 240264530",
          bank: "Numer konta bankowego<br/>Bank PKO BP: 76 1020 2472 0000 6702 0633 4629",
          mail: 'E-mail: ',
        },
      },
      de: {
        translation: {
          contact: "Kontakt",
          pricelist: "Preise",
          pricelist2: "Preise",
          competences: "Kompetenz",
          competences2: "Kompetenz",
          language: "PL",
          infoname:
            "Vereidigte Dolmetscherin <br/>und Übersetzerin <br/>für Polnisch und Deutsch.",
          do1: "Ich übersetze aus dem Polnischen ins Deutsche und aus dem Deutschen ins Polnische zu folgenden Fachgebieten: Motorisierung, Erziehung, Recht, Handel, Wirtschaft, Ökonomie, Buchhaltung, Versicherung, Medizin.",
          do2: "Ich arbeite mit den polnischen und deutschen Firmen, Behörden der Staatsverwaltung und Privatpersonen zusammen.",
          do3: "Ich freue mich auf eine erfolgreiche Zusammenarbeit mit Ihnen,",
          from: "",
          pricelistinfo1:
            "Eine Seite umfasst 1125 Zeichen inkl. Leerzeichen beglaubigter Übersetzung.<br/>Die Preise werden für jede angefangene Seite der Übersetzung berechnet.<br/><br/>Übersetzung ins Polnische / Deutsche:",
          pricelistinfo2:
            "Die angegebenen Preise sind Vertragspreise und können bei größeren Aufträgen oder ständiger Zusammenarbeit verhandelt werden.",
          pricelistitem1: "1 Seite der Übersetzung (100%)",
          pricelistitem2:
            "1 Seite der Übersetzung angefertigt am Tag des Auftrags (200%)",
          pricelistitem3: "Kopie (20%)",
          pricelistitem4:
            "Beglaubigung der Abschrift in der Fremdsprache (30%)",
          pricelistitem5:
            "Überprüfung und Beglaubigung einer vorgelegten Übersetzung",
          pricelistitem6: "Dolmetschen (für jede angefangene Stunde)",
          pricelistitem7: "Fahrzeugdokumente ab",
          pricelistitem8:
            "Übersetzung der Handschrift oder schwer lesbarer Kopie oder Fachtexten (150%)",
          contactinfoname:
            "Vereidigte Dolmetscherin und Übersetzerin für Polnisch und Deutsch.",
          street: "44-200 Rybnik/Oberschlesien <br/>ul. Borki 64F",
          phonenumber: "Handy +48 603 58 60 70",
          place:
            "Steuernummer 642-267-22-97 <br/>Statistische Nummer Regon 240264530",
          bank: "Bankkonto<br/> BIC: BPKOPLPW<br/> IBAN: 76 1020 2472 0000 6702 0633 4629",
          mail: 'E-Mail: ',
        },
      },
    },
  });
