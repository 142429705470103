import "./Contact.css";
// import Map from './Map.jpg'
import Mapa from "./Map";
import { Trans } from "react-i18next";

function Contact() {
  return (
    <div id="contact">
      <div id="contact-content">
        <div id="contact-info">
          <p id="kontakt">Kontakt</p>
          <p className="text-contact">
            <b>Romana Barylak</b>
            <br />
            <Trans i18nKey="contactinfoname">
              Tłumacz przysięgły języka niemieckiego
            </Trans>
          </p>
          <p className="text-contact">
            <Trans i18nKey="street">
              44-200 Rybnik
              <br />
              ul. Borki 64F
            </Trans>
          </p>
          <p className="text-contact">
            <Trans i18nKey="phonenumber">Tel. komórkowy +48 603 58 60 70</Trans>
          </p>
          <p className="text-contact">
            <Trans i18nKey="mail">E-mail:</Trans>
            <a href="mailto:romanabarylak@gmail.com">romanabarylak@gmail.com</a>
          </p>
          <p className="text-contact">
            <Trans i18nKey="place">
              NIP 642-267-22-97
              <br />
              Region 240264530
            </Trans>
          </p>
          <p className="text-contact">
            <Trans i18nKey="bank">
              Numer konta bankowego
              <br />
              Bank PKO BP: 76 1020 2472 0000 6702 0633 4629
            </Trans>
          </p>
        </div>
        <div id="map-content">
          <div id="map">
            {Mapa()}
            {/* mapa jest zrobiona tylko brakuje google maps api key */}
            {/* <a href="https://www.google.com/maps/place/Borki+64F,+44-200+Rybnik/@50.1125769,18.5163375,14.5z/data=!4m6!3m5!1s0x471148b3d63d5b3f:0x1064bf5ef07065a5!8m2!3d50.1142027!4d18.5121269!16s%2Fg%2F11j15k1dls?hl=pl-PL" target="blank"><img src={Map} alt="mapa"></img></a> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
